import React from 'react';
import {
    Box,
    Heading,
    Text,
    Badge,
    Link,
    List,
    ListItem,
    Image,
    Stack,
    Icon
} from "@chakra-ui/react";
import { HiOutlineExternalLink } from "react-icons/hi"

import books from "../../assets/projects/books_00.jpg"

const Ehon = () => {

    return (
        <Box maxW="container.sm">
            <Heading as="h5" size="sm" fontSize="lg">E-Hon</Heading>
            <Text mt={2}>
                A React-based Google Books library that queries and displays books based on user search.
                Uses NodeJS, Express and MongoDB so that users can review books and save them for later.
            </Text>
            <List mt={3}>
                <ListItem>
                    <Badge mr={2}>Website</Badge>
                    <Link color='teal.500' href='https://www.github.com/' isExternal>https://www.github.com <Icon as={HiOutlineExternalLink} /></Link>
                </ListItem>
                <ListItem>
                    <Badge mr={2}>Stack</Badge>
                    <span>MongoDB, Express, ReactJS and NodeJS</span>
                </ListItem>
            </List>
            <Stack spacing={4} mt={4}>
                <Image rounded="md" src={books} w="full" alt="project_name" />
            </Stack>
        </Box>
    );
};

export default Ehon;