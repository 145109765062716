import React from 'react';
import { Box, Link } from '@chakra-ui/react';
import { motion } from "framer-motion";
import { NavLink, Outlet } from "react-router-dom";
import { ColorModeSwitcher } from './ColorModeSwitcher';

const variants = {
    hidden: { opacity: 0, x: 0, y: 20 },
    enter: { opacity: 1, x: 0, y: 0 },
    exit: { opacity: 0, x: -0, y: 20 }
};

const Layout = () => {

    const PageLink = ({ children, ...rest }) => (
        <Link
            as={NavLink}
            _activeLink={{
                fontWeight: "semibold"
            }}
            {...rest}
        >
            {children}
        </Link>
    )

    return (
        <Box p={10} display="flex" alignItems="center" justifyContent="center" minH="100vh">
            <motion.article
                initial="hidden"
                animate="enter"
                exit="exit"
                variants={variants}
                transition={{ duration: 0.4, type: "easeInOut" }}
            >
                <Outlet />
            </motion.article>

            <Box
                textAlign="center"
                sx={{
                    position: 'fixed',
                    right: 0,
                    top: 0,
                    bottom: 0,
                    writingMode: 'vertical-rl',
                    transform: 'rotate(-180deg)',
                }}>
                <PageLink to="/" >Home</PageLink>&nbsp;•&nbsp;
                <PageLink to="/tools">Tools</PageLink>&nbsp;•&nbsp;
                <PageLink to="/projects">Projects</PageLink>&nbsp;•&nbsp;
                <PageLink to="/contact">Contact</PageLink>
            </Box>

            <ColorModeSwitcher />
        </Box>
    );
}

export default Layout;