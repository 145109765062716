import React from 'react';
import {
    Box,
    Heading,
    Text,
    Badge,
    Link,
    List,
    ListItem,
    Image,
    SimpleGrid,
    Stack,
    Icon
} from "@chakra-ui/react";
import { HiOutlineExternalLink } from "react-icons/hi"

import home from "../../assets/projects/colochunt_00.png"
import profiles from "../../assets/projects/colochunt_01.png"
import light from "../../assets/projects/colochunt_02.png"
import dark from "../../assets/projects/colochunt_03.png"

const Colochunt = () => {

    return (
        <Box maxW="container.sm" pt={20}>
            <Heading as="h5" size="md" fontSize="lg">ColocHunt</Heading>
            <Text mt={2}>
                A React-based web application to search for rooms and roommates that meet detailed search criteria.
                Uses Firebase Authentication and Firestore database.
            </Text>
            <List mt={3}>
                <ListItem>
                    <Badge mr={2}>Website</Badge>
                    <Link color='teal.500' href='https://colochunt.netlify.app' isExternal>https://colochunt.netlify.app/ <Icon as={HiOutlineExternalLink} /></Link>
                </ListItem>
                <ListItem>
                    <Badge mr={2}>Stack</Badge>
                    <span>ReactJS and Firebase</span>
                </ListItem>
            </List>
            <Stack spacing={4} mt={4}>
                <Image rounded="md" src={home} w="full" alt="project_name" />
                <Image rounded="md" src={profiles} w="full" alt="project_name" />
                <SimpleGrid columns={2} gap={2}>
                    <Image rounded="md" src={dark} alt="project_name" />
                    <Image rounded="md" src={light} alt="project_name" />
                </SimpleGrid>
            </Stack>
        </Box>
    );
};

export default Colochunt;