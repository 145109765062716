import React from 'react';
import { Link } from "react-router-dom";
import { SimpleGrid, Image, Heading, Text, Stack, Box, LinkOverlay, LinkBox } from "@chakra-ui/react";

import animage from "../assets/projects/animage.jpg"
import koosu from "../assets/projects/koosu.png"
import EHon from "../assets/projects/EHon.svg"
import ColocHunt from "../assets/projects/colochunt.svg"
import movies from "../assets/projects/movies_00.jpg"
import helpdesk from "../assets/projects/helpdesk.svg"

const Projects = () => {

  const Card = ({ to, image, title, subtitle }) => (
    <LinkBox as={Stack} maxW={60}>
      <Box pos="relative">
        <Image src="data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' width='720' height='400'/>" />
        <Image rounded="md" src={image} inset={0} pos="absolute" h="full" w="full" />
      </Box>
      <Stack spacing={1}>
        <LinkOverlay as={Link} to={to || "#"}>
          <Heading as="h5" size="sm">{title}</Heading>
        </LinkOverlay>
        <Text mt={32}>{subtitle}</Text>
      </Stack>
    </LinkBox>
  );

  return (
    <>
      <Heading as="h4" size="md" mb={4}>Projects</Heading>
      <SimpleGrid columns={{ base: 1, sm: 2, lg: 3 }} spacing={8}>
        <Card to="helpdesk" title='HelpDesk' subtitle="Tickets management system" image={helpdesk} />
        <Card to="colochunt" title='ColocHunt' subtitle="A web application to search for roommates" image={ColocHunt} />
        <Card to="koosu" title='Koosu' subtitle="E-Learning platform" image={koosu} />
        <Card to="ehon" title='E-Hon' subtitle="Books Library" image={EHon} />
        <Card to="movies" title='TV Tracker' subtitle="Movies and Tv shows tracker" image={movies} />
        <Card title='More' subtitle="Check my github" image={animage} />
      </SimpleGrid>
    </>
  );
};

export default Projects;