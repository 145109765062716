import React from 'react';
import {
    Box,
    Heading,
    FormControl,
    FormLabel,
    Input,
    Button,
    Textarea,
    Stack,
    FormHelperText
} from "@chakra-ui/react";

const Contact = () => {

    const handleSubmit = (event) => {
        event.preventDefault();
    };

    return (
        <Box>
            <Heading as="h5" size="sm">Contact</Heading>
            <Stack as="form" my={4} onSubmit={handleSubmit}>
                <Stack direction={{ base: "column", sm: "row" }}>
                    <FormControl isRequired>
                        <FormLabel>Name</FormLabel>
                        <Input type="text" placeholder="John Doe" />
                    </FormControl>
                    <FormControl isRequired>
                        <FormLabel>Email</FormLabel>
                        <Input type="email" placeholder="test@test.com" />
                    </FormControl>
                </Stack>
                <FormControl isRequired>
                    <FormLabel>Message</FormLabel>
                    <Textarea type="textarea" placeholder="Enter your message" />
                    <FormHelperText textColor="red.400">Contact form is disabled for now.</FormHelperText>
                </FormControl>
                <Button variant="outline" type="submit" width="full">
                    Submit
                </Button>
            </Stack>
        </Box>
    );
};

export default Contact;