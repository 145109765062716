import React from 'react';
import { Grid, GridItem } from "@chakra-ui/react";

import {
  FaReact,
  FaNodeJs,
  FaPython,
  FaHtml5,
  FaCss3Alt,
} from "react-icons/fa";
import { SiJavascript, SiMongodb, SiFirebase } from "react-icons/si";
// import { AiOutlineEllipsis } from "react-icons/ai"
import { motion } from "framer-motion";

const Tools = () => {

  const Tool = ({ element, color }) => (
    <GridItem>
      <motion.div
        whileHover={{ scale: 1.2, color: ["initial", color] }}
        whileTap={{ scale: 0.8 }}
        transition={{ duration: 0.2, }}
      >
        {element}
      </motion.div>
    </GridItem>
  );

  return (
    <Grid templateColumns="repeat(4, 1fr)" gap={6}>
      <Tool color="#61DAFB" element={<FaReact size={50} title="React" />} />
      <Tool color="#339933" element={<FaNodeJs size={50} title="NodeJS" />} />
      <Tool color="#3776AB" element={<FaPython size={50} title="Python" />} />
      <Tool color="#F7DF1E" element={<SiJavascript size={50} title="JavaScript" />} />
      <Tool color="#E34F26" element={<FaHtml5 size={50} title="HTML5" />} />
      <Tool color="#1572B6" element={<FaCss3Alt size={50} title="CSS3" />} />
      <Tool color="#47A248" element={<SiMongodb size={50} title="MongoDB" />} />
      <Tool color="#FFCA28" element={<SiFirebase size={50} title="Firebase" />} />
    </Grid>
  );
};

export default Tools;
