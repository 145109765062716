import React from 'react';
import {
    Box,
    Heading,
    Text,
    Badge,
    Link,
    List,
    ListItem,
    Image,
    Stack,
    Icon,
    SimpleGrid
} from "@chakra-ui/react";
import { HiOutlineExternalLink } from "react-icons/hi"

import ticketsIMG from "../../assets/projects/helpdesk_01.png"
import ticketsIMGLight from "../../assets/projects/helpdesk_02.png"
import loginDark from "../../assets/projects/helpdesk_03.png"
import loginLight from "../../assets/projects/helpdesk_04.png"


const HelpDesk = () => {

    return (
        <Box maxW="container.sm" pt={20}>
            <Heading as="h5" size="sm" fontSize="lg">HelpDesk</Heading>
            <Text mt={2}>
                A React-based tickets management system that allows operators to track and handle
                customers requests/issues.
            </Text>
            <List mt={3}>
                <ListItem>
                    <Badge mr={2}>Website</Badge>
                    <Link color='teal.500' href='https://www.github.com/' isExternal>https://www.github.com <Icon as={HiOutlineExternalLink} /></Link>
                </ListItem>
                <ListItem>
                    <Badge mr={2}>Stack</Badge>
                    <span>MongoDB, Express, ReactJS and NodeJS</span>
                </ListItem>
            </List>
            <Stack spacing={4} mt={4}>
                <Image rounded="md" src={ticketsIMG} w="full" alt="project_name" />
                <Image rounded="md" src={ticketsIMGLight} w="full" alt="project_name" />
                <SimpleGrid columns={2} gap={2}>
                    <Image rounded="md" src={loginDark} alt="project_name" />
                    <Image rounded="md" src={loginLight} alt="project_name" />
                </SimpleGrid>
            </Stack>
        </Box>
    );
};

export default HelpDesk;