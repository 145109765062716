import React from 'react';
import { Routes, Route, useLocation } from "react-router-dom";
import { AnimatePresence } from 'framer-motion';

import Layout from "./components/Layout";
import Profile from "./pages/Profile";
import Tools from "./pages/Tools";
import Projects from "./pages/Projects";
import Contact from './pages/Contact';
import NotFound from "./pages/NotFound";
import Ehon from './pages/projects/Ehon';
import Colochunt from './pages/projects/Colochunt';
import Koosu from './pages/projects/Koosu';
import Movies from './pages/projects/Movies';
import HelpDesk from './pages/projects/HelpDesk';


function App() {
  const location = useLocation();
  return (
    <AnimatePresence exitBeforeEnter>
      <Routes location={location} key={location.pathname}>
        <Route path="/" element={<Layout />} >

          <Route index element={<Profile />} />
          <Route path="tools" element={<Tools />} />
          <Route path="projects" element={<Projects />} />
          <Route path="contact" element={<Contact />} />

          <Route path='projects/helpdesk' element={<HelpDesk />} />
          <Route path='projects/colochunt' element={<Colochunt />} />
          <Route path='projects/koosu' element={<Koosu />} />
          <Route path='projects/ehon' element={<Ehon />} />
          <Route path='projects/movies' element={<Movies />} />

          <Route path='*' element={<NotFound />} />
        </Route>
      </Routes>
    </AnimatePresence>
  );
}

export default App;
