import React from 'react';
import {
    Box,
    Heading,
    Text,
    Badge,
    Link,
    List,
    ListItem,
    Image,
    Stack,
    Icon
} from "@chakra-ui/react";
import { HiOutlineExternalLink } from "react-icons/hi"

import movies from "../../assets/projects/movies_00.jpg"

const Movies = () => {

    return (
        <Box maxW="container.sm">
            <Heading as="h5" size="sm" fontSize="lg">TV Tracker</Heading>
            <Text mt={2}>
                Web application that helps discover, organize and keep track of Movies and TV shows.
                All of the data is pulled from the TMDB, a popular, user editable database for movies
                and TV shows.
            </Text>
            <List mt={3}>
                <ListItem>
                    <Badge mr={2}>Website</Badge>
                    <Link color='teal.500' href='https://www.github.com/' isExternal>https://www.github.com <Icon as={HiOutlineExternalLink} /></Link>
                </ListItem>
                <ListItem>
                    <Badge mr={2}>Stack</Badge>
                    <span>ReactJS and Firebase</span>
                </ListItem>
            </List>
            <Stack spacing={4} mt={4}>
                <Image rounded="md" src={movies} w="full" alt="project_name" />
            </Stack>
        </Box>
    );
};

export default Movies;