import React from 'react';
import { Text } from "@chakra-ui/react";

const NotFound = () => {

  return (
    <Text fontSize={18}>Are you lost ?</Text>
  );
};

export default NotFound;