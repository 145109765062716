import React from 'react';
import {
    Box,
    Heading,
    Text,
    Badge,
    Link,
    List,
    ListItem,
    Image,
    Stack,
    Icon
} from "@chakra-ui/react";
import { HiOutlineExternalLink } from "react-icons/hi"

import koosu1 from "../../assets/projects/koosu_01.png"
import koosu2 from "../../assets/projects/koosu_02.png"
import koosu3 from "../../assets/projects/koosu_03.png"
import Video from "../../assets/projects/koosu.mp4"

const Koosu = () => {

    return (
        <Box maxW="container.sm" pt={20}>
            <Heading as="h5" size="sm" fontSize="lg">Koosu</Heading>
            <Text mt={2}>
                E-Learning platform designed to improve online learning experience for learners.
                Uses NodeJS Backend and MongoDB database to provide and manage online courses in
                various topics and categories.
            </Text>
            <List mt={3}>
                <ListItem>
                    <Badge mr={2}>Website</Badge>
                    <Link color='teal.500' href='https://www.github.com/' isExternal>https://www.github.com <Icon as={HiOutlineExternalLink} /></Link>
                </ListItem>
                <ListItem>
                    <Badge mr={2}>Stack</Badge>
                    <span>MongoDB, Express, ReactJS and NodeJS</span>
                </ListItem>
            </List>
            <Stack spacing={4} mt={4}>
                <Image rounded="md" src={koosu1} w="full" alt="koosu" />
                <Image rounded="md" src={koosu2} w="full" alt="koosu" />
                <Image rounded="md" src={koosu3} w="full" alt="koosu" />
                <video controls>
                    <source src={Video} type="video/mp4" />
                    Your browser does not support HTML video.
                </video>
            </Stack>
        </Box>
    );
};

export default Koosu;