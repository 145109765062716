import React from 'react';
import avatar from '../assets/avatar.png';
import {
  Avatar,
  Text,
  Heading,
  Flex,
  HStack,
  IconButton,
  Link
} from "@chakra-ui/react";
import {
  FaGithub,
  FaTwitter,
  FaLinkedin,
  FaEnvelope,
} from "react-icons/fa";

const Profile = () => {

  return (
    <Flex flexDir="column" justifyContent="center" alignItems="center">
      <Avatar
        size="2xl"
        src={avatar}
      />
      <Heading mt={3} lineHeight="base" fontSize={24}>
        OUSSAMA REGASSE
      </Heading>
      <Text fontSize={18}>Full-Stack Ninja</Text>
      <HStack spacing={2} mt={4}>
        <IconButton
          as={Link}
          isExternal
          rel="noreferrer"
          href={process.env.REACT_APP_githubUrl}
          aria-label="icon"
          icon={<FaGithub />}
          size="md"
        />
        <IconButton
          as={Link}
          isExternal
          rel="noreferrer"
          href={process.env.REACT_APP_linkedinUrl}
          aria-label="icon"
          icon={<FaLinkedin />}
          size="md"
        />
        <IconButton
          as={Link}
          isExternal
          rel="noreferrer"
          href={process.env.REACT_APP_twitterUrl}
          aria-label="icon"
          icon={<FaTwitter />}
          size="md"
        />
        <IconButton
          as={Link}
          isExternal
          rel="noreferrer"
          href={`mailto:${process.env.REACT_APP_emailAddress}`}
          aria-label="icon"
          icon={<FaEnvelope />}
          size="md"
        />
      </HStack>
    </Flex>
  );
};

export default Profile;